export default {
  routeName: 'cart',
  newPosKeys: [
    'componentswitch',
    'shoppingCartPage',
    'pageBeshared',
  ],
  posKeys: [
    'RecommendExpandPop',
    'AllListQuickShip',
    'SellingPoint',
    'EmptyCartYouMayAlsoLike',
    'EmptyShopBagFloor',
  ],
}
