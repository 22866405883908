export default {
  routeName: 'orderReturnNew',
  newPosKeys: [
    'returnVideo', 
    'RefundAmountDetails', 
    'showCombineReturn',
    'gifcardExpirednotice',
    'accountRequired',
  ],
  posKeys: [],
}
